import React, { ReactNode, useState } from 'react'
import ButtonLink from "@/components/button_link.js"
import Card from "@/components/card.js"
import { Button } from "@/components/ui/button.js"
import { Separator } from "@/components/ui/separator.js"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible.js"
import { FaGoogle, FaMicrosoft } from 'react-icons/fa'
import { ChevronDown, ChevronUp } from 'lucide-react'

export interface AuthFormProps {
  children: ReactNode;
  title: string;
  description: string;
  footer?: ReactNode;
  formData?: any;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (event: React.FormEvent) => void;
}

export default function AuthForm({ children, title, description, footer, onSubmit }: AuthFormProps) {

  const [isEmailFormOpen, setIsEmailFormOpen] = useState(false)

  const oauth = (provider: string) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `/users/auth/${provider}`;

    // Add CSRF token input if necessary
    const meta = document.querySelector('meta[name="csrf-token"]')
    const csrfToken = meta ? meta.getAttribute('content') : '';
    if (csrfToken) {
      const csrfInput = document.createElement('input');
      csrfInput.type = 'hidden';
      csrfInput.name = 'authenticity_token';
      csrfInput.value = csrfToken;
      form.appendChild(csrfInput);
    }

    document.body.appendChild(form);
    form.submit();
  }

  return (
    <Card title={title} description={description} footer={footer}>
      <Button
          variant="secondary"
          className="w-full flex items-center justify-center space-x-2"
          onClick={() => oauth('google_oauth2')}
        >
          <FaGoogle className="w-5 h-5" />
          <span>{title} with Google</span>
        </Button>

        <Button
          variant="secondary"
          className="w-full flex items-center justify-center space-x-2"
          onClick={() => oauth('microsoft_office365')}
        >
          <FaMicrosoft className="w-5 h-5" />
          <span>{title} with Microsoft</span>
      </Button>

      <Separator className="my-4" />

      <Collapsible open={isEmailFormOpen} onOpenChange={setIsEmailFormOpen}>
        <CollapsibleTrigger asChild>
          <Button variant="secondary" className="w-full flex items-center justify-center">
            <span>Login with Email</span>
            {isEmailFormOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent className="mt-4 space-y-4">

        <form onSubmit={onSubmit} className="space-y-4">
          {children}
        </form>

        </CollapsibleContent>
      </Collapsible>
    </Card>
  )
}