import React from 'react'
import FlashMessage from './flash-message.js'

export interface Flash {
  notice?: string
  alert?: string
  error?: string
  warning?: string
}

interface FlashProps {
  flash: Flash
}

export const flashColors: Flash = {
  notice: "bg-blue-100 border-blue-500 text-blue-700",
  alert: "bg-red-100  border-red-500 text-red-700",
  error: "bg-red-100  border-red-500 text-red-700",
  warning: "bg-orange-100  border-orange-500 text-orange-700"
}

export default function Flash({ flash }: FlashProps) {

  return (
    <div className="fixed top-4 right-4">
      { Object.entries(flash).map(([type, message]) => 
        <FlashMessage type={type} key={type}>
          {message}
        </FlashMessage>
      )}
    </div>
  )
}
