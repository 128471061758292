import React from 'react'
import KnowledgeGraph from '@/components/knowledge_graph.js'
import { ConceptType } from '@/types/concept.js'
import { ConceptRelationType } from '@/types/concept_relation.js' 
import { Node, Edge } from '@xyflow/react'

export default function Index({ concepts, relations }: { concepts: ConceptType[], relations: ConceptRelationType[] }) {
  const entities = [] as Node[]
  concepts.forEach((concept, index) => (entities.push({
    id: concept.id.toString(),
    position: { x: 0, y: index * 100 },
    data: { label: concept.name },
    type: 'default'
  })))

  const edges = [] as Edge[]
  relations.forEach((relation) => (edges.push({
    id: relation.id.toString(),
    source: relation.from_concept_id.toString(),
    target: relation.to_concept_id.toString(),
    label: relation.name
  })))

  return <KnowledgeGraph entities={entities} relations={edges} />
}
