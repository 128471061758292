import React from "react"
import Chat, { ChatProps } from "../Chat/chat.js"
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area.js"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs.js"
import { IdeaType } from "@/types/idea.js"
import { IdeaRelationType } from "@/types/idea_relation.js"
import KnowledgeGraph, { positionNodes } from '@/components/knowledge_graph.js'

interface IdeaProps {
  chat: ChatProps
  ideas: IdeaType[]
  idea_relations: IdeaRelationType[]
}

export default function Index({ chat, ideas, idea_relations }: IdeaProps) {

  const entities = ideas.map((idea) => ({
    id: idea.id.toString(),
    data: { label: <div><span className="text-xs text-gray-500">{idea.concept_name}</span><br />{idea.name}</div> },
    position: { x: 0, y: 0 },
    type: 'default'
  }))

  const relations = idea_relations.map((relation) => ({
    id: relation.id.toString(),
    source: relation.idea_from_id.toString(),
    target: relation.idea_to_id.toString(),
    label: relation.name
  }))
  
  const { nodes, edges } = positionNodes(entities, relations)

  console.dir(edges, { depth: null })
 
  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-1/2">
        <Chat chat={chat} />
      </div>
      <div className="w-1/2 p-4 bg-gray-200">
        <Tabs defaultValue="map">
          <TabsList>
            <TabsTrigger value="map">Map</TabsTrigger>
            <TabsTrigger value="ideas">Ideas</TabsTrigger>
          </TabsList>
          {/* Map */}
          <TabsContent value="map">
            <div className="w-full h-[calc(100vh-8rem)] bg-white p-4 rounded-lg">
              <KnowledgeGraph nodes={nodes} edges={edges} />
            </div>
          </TabsContent>

          {/* Ideas */}
          <TabsContent value="ideas">
            <ScrollArea className="h-[calc(100vh-8rem)]">
              {ideas.map((idea) => (
                <div key={idea.id} className="mb-4 p-4 bg-white rounded-lg">
                  <h4 className="text-gray-500">
                    {idea.concept_name} - {idea.name}
                  </h4>
                  <p className="p-2 rounded">{idea.description}</p>
                </div>
              ))}
              <ScrollBar orientation="vertical" />
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </TabsContent>

        </Tabs>
      </div>
    </div>
  )
}
