import { Link, Head } from '@inertiajs/react'
import { Fragment } from 'react'
import Company from './Company'

export default function Index({ companies, flash }) {
  return (
    <>
      <Head title="Companies" />


        <div className="min-w-full">
          {companies.map((company) => (
            <Fragment key={company.id}>
              <Company company={company} />
              <p>
                <Link
                  href={`/companies/${company.id}`}
            className="ml-2 rounded-lg py-3 px-5 bg-gray-100 inline-block font-medium"
          >
            Show this company
          </Link>
        </p>
      </Fragment>
      ))}
    </div>
    </>
  )
}
