import React from 'react';
import { ConceptType } from '@/types/concept.js';
import { Handle, Position } from '@xyflow/react';
 
const handleStyle = { left: 10 };
 
export default function ConceptNode({ concept, isConnectable }: { concept: ConceptType, isConnectable: boolean }) {
 
  return (
    <div className="text-updater-node">
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div>
        <label htmlFor="text">Text:</label>
        <input id="text" name="text" onChange={onChange} className="nodrag" />
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={handleStyle}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </div>
  );
}