import React from 'react'
import QuestionForm, { Question } from './Form.js'

export default function New({ question }: { question: Question }) {
  return (
    <QuestionForm
      question={question}
      onSubmit={(form) => {
        form.transform((data: Question) => ({ question: data }))
        form.post(`/admin/questions/${question.question_id}/questions`)
      }}
      submitText="Create Question"
    />
  )
}
