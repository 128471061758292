import React from 'react'
import { Link, Head } from '@inertiajs/react'

export default function Dashboard() {
  return (
    <ul>
      <li><Link href="/admin/questions">Questions</Link></li>
      <li><Link href="/admin/concepts">Concepts</Link></li>
    </ul>
  )
}