import React, { useEffect, useRef } from 'react';
import { ScrollArea } from "@/components/ui/scroll-area.js"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip.js"
import ReactMarkdown from "react-markdown"
import { MessageType } from "@/types/message.js"
import { FileIcon, FileImageIcon, FileTextIcon, FileArchiveIcon } from 'lucide-react';

interface MessageListProps {
  messages: MessageType[];
}

const getFileIcon = (contentType: string) => {
  switch (true) {
    case contentType.includes('image'):
      return <FileImageIcon className="h-4 w-4" />;
    case contentType.includes('pdf'):
      return <FileTextIcon className="h-4 w-4" />;
    case contentType.includes('zip') || contentType.includes('rar'):
      return <FileArchiveIcon className="h-4 w-4" />;
    default:
      return <FileIcon className="h-4 w-4" />;
  }
};

const MessageList: React.FC<MessageListProps> = ({ messages }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <ScrollArea className="h-full" id="message-list">
      <div className="space-y-4 pr-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`mb-4 p-2 rounded-lg ${
              message.role === 'user' ? 'bg-blue-100 ml-auto' : 'bg-gray-100'
            } max-w-[80%]`}
          >
            <div className="space-y-2">
              <ReactMarkdown>{message.content}</ReactMarkdown>
              
              {message.file && (
                <div className="flex items-center gap-2 mt-2 text-sm text-gray-600">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <a 
                          href={message.file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center gap-1 hover:text-blue-600"
                          aria-label={`Download ${message.file.filename}`}
                        >
                          {getFileIcon(message.file.content_type)}
                          <span className="underline">Attachment</span>
                        </a>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{message.file.filename}</p>
                        <p className="text-xs text-gray-500">
                          {(message.file.byte_size / 1024).toFixed(1)}KB
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </ScrollArea>
  );
};

export default MessageList;