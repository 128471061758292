import { Link, Head } from '@inertiajs/react'
import Form from './Form.js'

export default function New({ chat }) {
  return (
    <>
      <Head title="New chat" />

      <div className="mx-auto md:w-2/3 w-full px-8 pt-8">
        <h1 className="font-bold text-4xl">New chat</h1>

        <Form
          chat={chat}
          onSubmit={(form) => {
            form.transform((data) => ({ chat: data }))
            form.post('/chats')
          }}
          submitText="Create chat"
        />

        <Link
          href="/chats"
          className="ml-2 rounded-lg py-3 px-5 bg-gray-100 inline-block font-medium"
        >
          Back to chats
        </Link>
      </div>
    </>
  )
}
