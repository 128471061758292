import React, { useState, useEffect } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

interface Message {
  id: number
  content: string
  role: 'user' | 'assistant'
}

interface Artifact {
  id: number
  type: 'image' | 'code'
  content: string
}

export default function ChatInterface() {
  const [messages, setMessages] = useState<Message[]>([])
  const [inputMessage, setInputMessage] = useState('')
  const [artifacts, setArtifacts] = useState<Artifact[]>([])

  const handleSendMessage = () => {
    if (inputMessage.trim() === '') return

    const newMessage: Message = {
      id: Date.now(),
      content: inputMessage,
      role: 'user',
    }

    setMessages((prevMessages) => [...prevMessages, newMessage])
    setInputMessage('')

    // Simulate assistant response
    setTimeout(() => {
      const assistantMessage: Message = {
        id: Date.now(),
        content: "Here's a sample artifact for you.",
        role: 'assistant',
      }
      setMessages((prevMessages) => [...prevMessages, assistantMessage])

      // Generate a sample artifact
      const newArtifact: Artifact = {
        id: Date.now(),
        type: 'code',
        content: 'console.log("Hello, World!");',
      }
      setArtifacts((prevArtifacts) => [...prevArtifacts, newArtifact])
    }, 1000)
  }

  useEffect(() => {
    // Scroll to bottom of message list when new messages are added
    const messageList = document.getElementById('message-list')
    if (messageList) {
      messageList.scrollTop = messageList.scrollHeight
    }
  }, [messages])

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="flex flex-col w-1/2 p-4 bg-white">
        <ScrollArea className="flex-grow mb-4" id="message-list">
          {messages.map((message) => (
            <div
              key={message.id}
              className={`mb-4 p-2 rounded-lg ${
                message.role === 'user' ? 'bg-blue-100 ml-auto' : 'bg-gray-100'
              } max-w-[80%]`}
            >
              {message.content}
            </div>
          ))}
        </ScrollArea>
        <div className="flex">
          <Input
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            placeholder="Type your message..."
            className="flex-grow mr-2"
          />
          <Button onClick={handleSendMessage}>Send</Button>
        </div>
      </div>
      <div className="w-1/2 p-4 bg-gray-200">
        <Tabs defaultValue="artifacts">
          <TabsList>
            <TabsTrigger value="artifacts">Artifacts</TabsTrigger>
            <TabsTrigger value="preview">Preview</TabsTrigger>
          </TabsList>
          <TabsContent value="artifacts">
            <ScrollArea className="h-[calc(100vh-8rem)]">
              {artifacts.map((artifact) => (
                <div key={artifact.id} className="mb-4 p-4 bg-white rounded-lg">
                  <h3 className="font-bold mb-2">
                    {artifact.type === 'image' ? 'Image' : 'Code'} Artifact
                  </h3>
                  {artifact.type === 'image' ? (
                    <img src={artifact.content} alt="Generated artifact" className="max-w-full h-auto" />
                  ) : (
                    <pre className="bg-gray-100 p-2 rounded">{artifact.content}</pre>
                  )}
                </div>
              ))}
            </ScrollArea>
          </TabsContent>
          <TabsContent value="preview">
            <div className="h-[calc(100vh-8rem)] bg-white p-4 rounded-lg">
              Preview content would go here
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}