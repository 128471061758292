import React from 'react'
import QuestionForm, { Question } from './Form.js'

export default function Edit({ question }: { question: Question }) {
  return (
    <QuestionForm
      question={question}
      onSubmit={(form) => {
        form.transform((data: Question) => ({ question: data }))
        form.update(`/admin/questions/${question.id}`)
      }}
      submitText="Update Question"
    />
  )
}
