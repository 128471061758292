import React from 'react'
import { useForm } from '@inertiajs/react'
import { Link } from '@inertiajs/react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import Form from '@/components/form'

export default function ResetPassword() {
  const form = useForm({
    password: '',
    password_confirmation: '',
  })

  const { data, setData, errors, processing } = form

  const onSubmit = (e) => {
    e.preventDefault()
    form.transform(data => ({user: data}))
    form.put('/users/password')
  }

  const onChange = (e) => {
    setData(e.target.id, e.target.value)
  }

  return (
    <Form
      title="Reset Password"
      description="Enter your new password"
      onSubmit={onSubmit}
      footer={
        <Link href="/users/sign_in" className="text-sm text-blue-600 hover:underline">
          Back to sign in
        </Link>
      }
    >
      <Input
        id="password"
        type="password"
        placeholder="new password"
        value={data.password}
        onChange={onChange}
        required
      />

      <Input
        id="password-confirmation"
        type="password"
        placeholder="password confirmation"
        value={data.password_confirmation}
        onChange={onChange}
        required
      />

      <Button type="submit" className="w-full">Reset Password</Button>
    </Form>
  )
}