import React from 'react'
import { useForm, Link } from '@inertiajs/react'
import { Input } from "@/components/ui/input.js"
import AuthForm from './AuthForm.js'
import ButtonLink from '@/components/button_link.js'
import { Button } from '@/components/ui/button.js'

export default function SignUp() {
  const form = useForm({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
  })

  const { data, setData, errors, processing } = form

  const onSubmit = (e) => {
    e.preventDefault();
    form.transform(data => ({user: data}))
    form.post('/users')
  }

  const onChange = (e) => {
    setData(e.target.id, e.target.value)
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit(e)
    }
  }

  return (
    <AuthForm
      title="Sign Up"
      description="Create a new account"
      onSubmit={onSubmit}
      footer={
        <ButtonLink variant="link" href="/users/sign_in" className="text-sm">
          Already have an account? Sign in
        </ButtonLink>
      }
    >
      <div className="grid grid-cols-2 gap-4">
        <Input
          id="first_name"
          type="text"
          placeholder="first name"
          value={data.first_name}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />

        <Input
          id="last_name"
          type="text"
          placeholder="last name"
          value={data.last_name}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
      </div>

      <Input
        id="email"
        type="email"
        placeholder="email"
        value={data.email}
        onKeyDown={onKeyDown}
        onChange={onChange}
        required
      />
      {errors.email && (
        <div className="text-red-500 px-3 py-2 font-medium">
          {errors.email.join(', ')}
       </div>
      )}

      <Input
        id="password"
        type="password"
        placeholder="password"
        value={data.password}
        onKeyDown={onKeyDown}
        onChange={onChange}
        required
      />
      {errors.password && (
        <div className="text-red-500 px-3 py-2 font-medium">
          {errors.password.join(', ')}
        </div>
      )}

      <Input
        id="password_confirmation"
        type="password"
        placeholder="password confirmation"
        value={data.password_confirmation}
        onKeyDown={onKeyDown}
        onChange={onChange}
        required
      />
      {errors.password_confirmation && (
        <div className="text-red-500 px-3 py-2 font-medium">
          {errors.password_confirmation.join(', ')}
        </div>
      )}

      <Button 
        type="submit"
        disabled={processing}
        className="w-full"
      >Sign up</Button>

    </AuthForm>
  )
}