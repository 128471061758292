import React from 'react'
import { useForm } from '@inertiajs/react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import Form from '@/components/form'
import { Link } from '@inertiajs/react'

export default function ForgotPassword() {
  const form = useForm({
    email: '',
  })

  const { data, setData, errors, processing } = form
  
  const onSubmit = (e) => {
    e.preventDefault()
    form.transform(data => ({user: data}))
    form.post('/users/password')
  }

  const onChange = (e) => {
    setData(e.target.id, e.target.value)
  }

 
  return (
    <Form
      title="Forgot Password"
      description="Enter your email to reset your password"
      onSubmit={onSubmit}
      footer={
        <Link href="/users/sign_in" className="text-sm text-blue-600 hover:underline">
          Back to sign in
        </Link>
      }
    >
      <Input
        id="email"
        type="email"
        placeholder="email"
        value={data.email}
        onChange={onChange}
        required
      />

      <Button type="submit" className="w-full">Send reset instructions</Button>
    </Form>
  )
}