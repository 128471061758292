import React, { useState, useEffect, useRef } from 'react'
import { consumer } from '@/channels/consumer.js'
import { usePost, useGet } from "#use-fetch"
import MessageInput from '@/pages/Message/input.js'
import MessageList from '@/pages/Message/list.js'
import { MessageType } from '@/types/message.js'

export interface ChatProps {
  id: number
  title: string
  messages: MessageType[]
}

export default function Chat({chat}: {chat: ChatProps}) {
  const [messages, setMessages] = useState<MessageType[]>(chat?.messages || [])
  const [aiMessageId, setAiMessageId] = useState('')

  const { data, loading, error, postData} = usePost<MessageType>(`/chats/${chat.id}/messages`)

  const handleSendMessage = (input: string, uploaded_file?: File) => {
    postData({ content: input, role: 'user', uploaded_file: uploaded_file })
  }

  useEffect(() => {
    if(data) {
      setMessages((prevMessages) => [...prevMessages, data.message])
    }
  }, [data])

  useEffect(() => {
    // Set up Action Cable subscription
    const subscription = consumer.subscriptions.create(
      {channel: "ChatChannel", chat_id: chat.id}, 
      {
        received: (data: { message_id: number }) => {
          // Fetch the new message by ID
          console.log(`data received: ${data.message_id}`)
            setAiMessageId(data.message_id.toString())
          },
        }
      ) 
    return () => {
      console.log('unsubscribing')
      subscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    // Fetch the AI message when the AI message ID changes
    console.log('fetching AI message')
    if (aiMessageId === '') return
    fetch(`/messages/${aiMessageId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setMessages(prevMessages => [...prevMessages, data.message])
      })
      .catch(error => {
        // Handle errors
        console.error('Error fetching message:', error)
      })

  }, [aiMessageId])

  return (
    <div className="flex flex-col h-[calc(100vh-3rem)] bg-gray-100">
      <div className="flex flex-col w-full p-4 bg-white">
        <div className="flex-grow overflow-hidden">
          <MessageList messages={messages} />
        </div>
        <MessageInput onInput={handleSendMessage} loading={loading} />
      </div>
    </div>
  )
}