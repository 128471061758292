import { useState } from 'react';

// Function to get the CSRF token from meta tags
const getCsrfToken = () => {
  const meta = document.querySelector('meta[name="csrf-token"]');
  return meta?.getAttribute('content') || '';
};

// Helper function to check if an object contains File instances
const hasFiles = (obj: any): boolean => {
  if (obj instanceof File) return true;
  if (typeof obj !== 'object' || obj === null) return false;
  return Object.values(obj).some(value => hasFiles(value));
};

// Helper function to convert payload to FormData
const convertToFormData = (payload: any): FormData => {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else if (typeof value === 'object' && value !== null) {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, String(value));
    }
  });
  return formData;
};

const useFetch = <T,>(url: string, method: string) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async (payload?: any) => {
    setLoading(true);
    setError(null);

    try {
      const containsFiles = payload && hasFiles(payload);
      const headers: HeadersInit = {
        'X-CSRF-Token': getCsrfToken(),
      };
      
      if (!containsFiles) {
        headers['Content-Type'] = 'application/json';
      }

      const body = payload
        ? containsFiles
          ? convertToFormData(payload)
          : JSON.stringify(payload)
        : undefined;

      const response = await fetch(url, {
        method,
        headers,
        body,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchData };
};

export const useGet = <T,>(url: string) => {
  const { data, loading, error, fetchData } = useFetch<T>(url, 'GET');
  return { data, loading, error, getData: fetchData };
};

export const usePost = <T,>(url: string) => {
  const { data, loading, error, fetchData } = useFetch<T>(url, 'POST');
  return { data, loading, error, postData: fetchData };
};

export const usePut = <T,>(url: string) => {
  const { data, loading, error, fetchData } = useFetch<T>(url, 'PUT');
  return { data, loading, error, putData: fetchData };
};

export const usePatch = <T,>(url: string) => {
  const { data, loading, error, fetchData } = useFetch<T>(url, 'PATCH');
  return { data, loading, error, patchData: fetchData };
};

export const useDelete = <T,>(url: string) => {
  const { data, loading, error, fetchData } = useFetch<T>(url, 'DELETE');
  return { data, loading, error, patchData: fetchData };
};

export default useFetch;
