import React, { useState } from 'react'
import { ChevronRight, ChevronDown, Plus, Edit, Trash2 } from 'lucide-react'
import ButtonLink from "@/components/button_link.js"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip.js"

interface Question {
  id: string
  name: string
  text: string
  position: number
  questions_count: number
  questions?: Question[]
}

interface QuestionNodeProps {
  question: Question
  level: number
}

const QuestionNode: React.FC<QuestionNodeProps> = ({ question, level }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [isHovered, setIsHovered] = useState(false)
  const [isSelected, setIsSelected] = useState(false)

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  return (
    <div 
      className="mb-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setIsSelected(!isSelected)}
    >
      <div 
        className={`flex items-center p-2 rounded-md transition-colors duration-150 ease-in-out cursor-pointer
                    ${isSelected ? 'bg-blue-100' : isHovered ? 'bg-gray-100' : ''}`}
        style={{ marginLeft: `${level * 20}px` }}
      >
        <div className="flex items-center flex-grow" onClick={toggleExpand}>
          {question.questions && question.questions.length > 0 ? (
            isExpanded ? <ChevronDown className="w-4 h-4 mr-2" /> : <ChevronRight className="w-4 h-4 mr-2" />
          ) : (
            <div className="w-4 h-4 mr-2" /> // Placeholder for alignment
          )}
          <div>
            <span className="font-semibold">{question.name}</span>
            <span className="text-sm text-gray-500 ml-2">({question.questions_count})</span>
            <p className="text-sm text-gray-700 mt-1">{question.text}</p>
          </div>
        </div>
        {(isHovered || isSelected) && (
          <TooltipProvider>
            <div className="flex items-center space-x-2">
              <Tooltip>
                <TooltipTrigger asChild>
                  <ButtonLink 
                    href={`/admin/questions/${question.id}/questions/new`} 
                    variant="ghost" 
                    size="icon" 
                  >
                    <Plus className="h-4 w-4" />
                  </ButtonLink>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Add Child Question</p>
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <ButtonLink 
                    href={`/admin/questions/${question.id}/edit`} 
                    variant="ghost" 
                    size="icon" 
                  >
                    <Edit className="h-4 w-4" />
                  </ButtonLink>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Edit Question</p>
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <ButtonLink 
                    href={`/admin/questions/${question.id}`} 
                    method="delete"
                    variant="ghost" 
                    size="icon" 
                    onBefore={(e: React.MouseEvent) => confirm('Are you sure you want to delete this question?')}
                  >
                    <Trash2 className="h-4 w-4" />
                  </ButtonLink>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Delete Question</p>
                </TooltipContent>
              </Tooltip>
            </div>
          </TooltipProvider>
        )}
      </div>
      {isExpanded && question.questions && (
        <div className="ml-6 mt-2 border-l-2 border-gray-200 pl-4">
          {question.questions.map((subQuestion) => (
            <QuestionNode 
              key={subQuestion.id} 
              question={subQuestion} 
              level={level + 1}
            />
          ))}
        </div>
      )}
    </div>
  )
}

interface QuestionTreeViewProps {
  questions: Question[]
  onAddChild: (parentId: string) => void
  onEdit: (question: Question) => void
  onDelete: (questionId: string) => void
}

export default function QuestionTreeView({ 
  questions = [], 
  onAddChild, 
  onEdit, 
  onDelete 
}: QuestionTreeViewProps) {
  return (
    <div className="p-4 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-bold mb-4">Question Tree</h2>
      {questions.map((question) => (
        <QuestionNode 
          key={question.id} 
          question={question} 
          level={0}
        />
      ))}
    </div>
  )
}