import React, { ReactNode } from 'react';
import { Card as CardBase, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card.js"
import ButtonLink from '@/components/button_link.js';
import { X } from 'lucide-react';

export interface CardProps {
  children: ReactNode;
  title: string;
  description?: string;
  footer?: ReactNode;
}

function cancel(event: React.MouseEvent<HTMLLinkElement>) {
  event.preventDefault();
  window.history.back();
}

export default function Card({ children, title, description, footer }: CardProps) {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <CardBase className="w-full max-w-md relative">
        <ButtonLink
          href="#"
          variant="ghost"
          className="absolute right-2 top-2"
          onClick={cancel}
          aria-label="Cancel"
        >
          <X className="h-4 w-4" />
        </ButtonLink>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardHeader>
        <CardContent>
          {children}
        </CardContent>
        <CardFooter className="flex flex-col space-y-2">
          {footer}
        </CardFooter>
      </CardBase>
    </div>
  )
}