import { createInertiaApp } from '@inertiajs/react'
import { createElement, ReactNode } from 'react'
import { createRoot } from 'react-dom/client'
import Layout from '../layouts/layout.js'

createInertiaApp({
  // Set default page title
  // see https://inertia-rails.netlify.app/guide/title-and-meta
  title: title => title ? `${title} - b4cast` : 'b4cast',

  // Disable progress bar
  //
  // see https://inertia-rails.netlify.app/guide/progress-indicators
  // progress: false,

  resolve: (name: string) => {
    const pages = import.meta.glob('../pages/**/!(*.stories).tsx', { eager: true })
    //return pages[`../pages/${name}.tsx`] as any

    // To use a default layout, import the Layout component
    // and use the following lines.
    // see https://inertia-rails.netlify.app/guide/pages#default-layouts
    const page = pages[`../pages/${name}.tsx`] as any;
    !page && console.error('page not found: ', name)
    if (page.default.layout === undefined) {
      page.default.layout = (page: React.ReactNode) => createElement(Layout, { children: page });
    }
    return page;
  },

  setup({ el, App, props }: { el: HTMLElement; App: React.ComponentType<any>; props: any }) {
    const root = createRoot(el)
    root.render(createElement(App, props))
  },
})
