import React from 'react'
import { useForm } from '@inertiajs/react'
import Form from '@/components/form.js'
import { Input } from '@/components/ui/input.js'
import { Textarea } from '@/components/ui/textarea.js'
import { Button } from '@/components/ui/button.js'
import { Label } from '@/components/ui/label.js'

export interface Question {
  id: number
  name: string
  text: string
  question_id: number
}

type QuestionFormProps = {
  question: Question
  onSubmit: (form: any) => void
  submitText: string
}

export default function QuestionForm({ question, onSubmit, submitText }: QuestionFormProps) {
  const form = useForm({
    name: question.name || '',
    text: question.text || '',
  })
  const { data, setData, errors, processing } = form

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(form)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.id, e.target.value)
  }

  return (
    <Form title="Question" onSubmit={handleSubmit} description="Business Idea Question">
      <Label htmlFor="name">Summary Name*</Label>
      <Input type="text" id="name" placeholder="name" value={data.name} required onChange={handleChange} />
      <Label htmlFor="text" className="mt-4">Question Text*</Label>
      <Textarea id="text" placeholder="question" value={data.text} required onChange={handleChange}/>
      <Button 
        type="submit"
        disabled={processing}
        className="w-full"
      >{submitText}</Button>

    </Form>
  )
}