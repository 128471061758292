import React from 'react';
import { Company } from "./Company";
import Form from "@/components/form";
import { useForm } from '@inertiajs/react';
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

export interface Country {
  code: string;
  name: string;
}

interface CompanyFormProps {
  company: Company,
  onSubmit: Function,
  submitText: String,
  countries: Country[],
}

export default function CompanyForm({ company, onSubmit, submitText, countries}: CompanyFormProps) {
  const form = useForm({
    name: company.name || '',
    website: company.website || '',
    address1: company.address1 || '',
    address2: company.address2 || '',
    city: company.city || '',
    postal: company.postal || '',
    region: company.region || '',
    country: company.country || '',
  });

  const { data, setData, errors, processing } = form

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(form)
  }

  const onChange = (e) => {
    setData(e.target.id, e.target.value)
  }

  return (
    <Form
      title="Company"
      description='Enter a company'
      onSubmit={handleSubmit}
    >
      <Input
        type="text"
        placeholder="name"
        id="name"
        value={data.name}
        onChange={onChange}
      />
      {errors.name && (
        <div className="text-red-500 px-3 py-2 font-medium">
          {errors.name.join(', ')}
        </div>
      )}

      <Input
        type="url"
        placeholder="website"
        id="website"
        value={data.website}
        onChange={onChange}
      />
      {errors.website && (
        <div className="text-red-500 px-3 py-2 font-medium">
          {errors.website.join(', ')}
        </div>
      )}
      
      <div className="grid grid-cols-2 gap-4">
          <Input
            type="text"
            placeholder="address1"
            id="address1"
            value={data.address1}
            onChange={onChange}
          />
          {errors.address1 && (
            <div className="text-red-500 px-3 py-2 font-medium">
              {errors.address1.join(', ')}
            </div>
          )}

          <Input
            type="text"
            placeholder="address2"
            id="address2"
            value={data.address2}
            onChange={onChange}
          />
          {errors.address2 && (
            <div className="text-red-500 px-3 py-2 font-medium">
              {errors.address2.join(', ')}
            </div>
          )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Input
          type="text"
          placeholder="city"
          id="city"
          value={data.city}
          onChange={onChange}
        />
        {errors.city && (
          <div className="text-red-500 px-3 py-2 font-medium">
            {errors.city.join(', ')}
          </div>
        )}

      <Input
        type="text"
        placeholder="region"
        id="region"
        value={data.region}
        onChange={onChange}
      />
      {errors.region && (
        <div className="text-red-500 px-3 py-2 font-medium">
          {errors.region.join(', ')}
        </div>
      )}
      </div>
      <div className="grid grid-cols-4 gap-4">

      <Input
        type="text"
        placeholder="postal"
        id="postal"
        value={data.postal}
        onChange={onChange}
      />
      {errors.postal && (
        <div className="text-red-500 px-3 py-2 font-medium">
          {errors.postal.join(', ')}
        </div>
      )}

      <Select 
        onValueChange={value => {setData("country",value)}}
        defaultValue={data.country}
      >
        <SelectTrigger className='text-slate-700 hover:bg-slate-300 col-span-3'>
          <SelectValue placeholder="Select a country" />
        </SelectTrigger>
        <SelectContent>
          {countries.map((country) => (
            <SelectItem key={country.code} value={country.name}>
              {country.name}
            </SelectItem>
          ))}
          </SelectContent>
      </Select>
      </div>

      <div className="inline">
        <button
          type="submit"
          disabled={processing}
          className="rounded-lg py-3 px-5 bg-blue-600 text-white inline-block font-medium cursor-pointer"
        >
          {submitText}
        </button>
      </div>
    </Form>
  )
}
