import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.js"
import { Badge } from "@/components/ui/badge.js"
import { Building2, Globe, MapPin } from "lucide-react"
import { CompanyType } from "@/types/company.js"

interface CompanyProps {
  company: CompanyType;
}
export default function Company({ company }: CompanyProps) {
  const { name, website, address1, address2, city, postal, region, country } = company
  const address = [address1, address2, city, postal, region, country].filter(Boolean).join(', ')

  return (
    <div>
      <p className="my-5">
        <strong className="block font-medium mb-1">Name:</strong>
        {company.name?.toString()}
      </p>
      <p className="my-5">
        <strong className="block font-medium mb-1">Website:</strong>
        {company.website?.toString()}
      </p>
      <p className="my-5">
        <strong className="block font-medium mb-1">Address1:</strong>
        {company.address1?.toString()}
      </p>
      <p className="my-5">
        <strong className="block font-medium mb-1">Address2:</strong>
        {company.address2?.toString()}
      </p>
      <p className="my-5">
        <strong className="block font-medium mb-1">City:</strong>
        {company.city?.toString()}
      </p>
      <p className="my-5">
        <strong className="block font-medium mb-1">Postal:</strong>
        {company.postal?.toString()}
      </p>
      <p className="my-5">
        <strong className="block font-medium mb-1">Region:</strong>
        {company.region?.toString()}
      </p>
      <p className="my-5">
        <strong className="block font-medium mb-1">Country:</strong>
        {company.country?.toString()}
      </p>
    </div>
  )
}
