import React, { useState, useRef, useEffect } from 'react';
import { Textarea } from '@/components/ui/textarea.js';
import { Button } from '@/components/ui/button.js';
import { Input } from '@/components/ui/input.js';
import { Label } from '@/components/ui/label.js';
import { Paperclip, X, Send } from 'lucide-react';

interface MessageInputProps {
  onInput: (message: string, uploaded_file?: File) => void;
  loading: boolean;
  progress?: { percentage: number };
}

const MessageInput: React.FC<MessageInputProps> = ({ onInput, loading, progress }) => {
  const [inputMessage, setInputMessage] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputMessage]);

  const onClick = () => {
    if (inputMessage.trim() === '' && !uploadedFile) return;
    onInput(inputMessage, uploadedFile || undefined);
    setInputMessage('');
    setUploadedFile(null);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setUploadedFile(e.target.files[0]);
    }
  };

  return (
    <div className="space-y-2" role="region" aria-label="Message input area">
      <div className="rounded-lg border bg-background">
        <Textarea
          ref={textareaRef}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (!loading) onClick();
            }
          }}
          placeholder="Type your message..."
          className="min-h-[48px] border-0 focus-visible:ring-0 resize-none m-1"
          disabled={loading}
          aria-label="Message content"
          data-testid="message-input"
        />
        <div className="flex justify-between items-center px-3 py-2" role="toolbar" aria-label="Message actions">
          <div className="flex items-center gap-2">
            {uploadedFile && (
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setUploadedFile(null)}
                className="h-8 w-8 text-red-500 hover:text-red-600"
                aria-label="Remove file"
                data-testid="remove-file-button"
              >
                <X className="h-4 w-4" />
              </Button>
            )}
            <Label htmlFor="file-upload" className="cursor-pointer">
              <Input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleFileChange}
                disabled={loading}
                aria-label="Upload file"
                data-testid="file-upload-input"
              />
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                type="button"
                asChild
                aria-label="Attach file"
                data-testid="attach-file-button"
              >
                <span>
                  <Paperclip className="h-4 w-4" />
                </span>
              </Button>
            </Label>
          </div>
          
          <Button
            variant="ghost"
            size="icon"
            onClick={onClick}
            disabled={loading}
            className="h-8 w-8"
            aria-label="Send message"
            data-testid="send-message-button"
          >
            <Send className="h-4 w-4" />
          </Button>
        </div>
      </div>
      {(uploadedFile || progress) && (
        <div 
          className="space-y-2 pl-3" 
          role="status" 
          aria-live="polite"
        >
          {uploadedFile && (
            <div 
              className="text-sm text-gray-500 flex items-center gap-2"
              data-testid="file-name-display"
            >
              <Paperclip className="h-4 w-4" />
              {uploadedFile.name}
            </div>
          )}
          {progress && (
            <div className="w-full">
              <progress 
                value={progress.percentage} 
                max="100"
                className="w-full h-2 [&::-webkit-progress-bar]:bg-gray-200 [&::-webkit-progress-value]:bg-blue-500"
                aria-label="Upload progress"
                data-testid="upload-progress"
              >
                {progress.percentage}%
              </progress>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageInput;