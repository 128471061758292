import React from 'react';
import ButtonLink from '@/components/button_link.js';
import { Link, router } from '@inertiajs/react';
import { Button } from "@/components/ui/button.js"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.js"
import { ChevronDown, Plus, User as UserIcon, Lock, LogOut } from 'lucide-react'

import b4cast_logo from '@/assets/b4cast_logo.png'
import { UserType } from '@/types/user.js'
import { CompanyType } from '@/types/company.js'

export interface HeaderProps {
  user: UserType | null;
}

export default function Header({user}: HeaderProps) {
  const name = user?.first_name || user?.email.split('@')[0];
  const current_company = user?.current_company;
  const companies = user?.companies || [];

  const changeCompany = (company: CompanyType) => {
    router.get(`/companies/${company.id}`);
  }

  const newCompany = () => {
    router.get('/companies/new');
  }

  const showProfile = () => {
    user && router.get(`/users/profile`);
  }

  const signOut = () => {
    router.delete('/users/sign_out');
  }

  const companySelector = () => {
    return ( current_company ?
      <div className="flex-grow mx-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="text-slate-500 w-full justify-between md:w-64">
              {current_company.name}
              <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full md:w-64">
          {companies.map((company) => (
            <DropdownMenuItem
              key={company.id}
              onSelect={() => changeCompany(company)}
              className={current_company?.id === company.id ? 'bg-slate-100' : ''}
            >
              {company.name}
            </DropdownMenuItem>
          ))}
          <DropdownMenuSeparator />
          <DropdownMenuItem onSelect={newCompany}>
            <Plus className="mr-2 h-4 w-4" />
            Add New Company
          </DropdownMenuItem>
        </DropdownMenuContent>
       </DropdownMenu>
      </div>
     : <ButtonLink variant="ghost" href="/companies/new">
        <Plus className="mr-2 h-4 w-4" />
        Add Company
      </ButtonLink>
    )
  };

  const userMenu = () => {
    return (
      <div className="flex-shrink-0">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <span className="flex items-center space-x-2">
              <UserIcon className="h-4 w-4" />
              <span>{name}</span>
                <ChevronDown className="h-4 w-4" />
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{user?.email}</DropdownMenuLabel>
            <DropdownMenuItem onSelect={showProfile}>
              <UserIcon className="mr-2 h-4 w-4" />
              Profile
            </DropdownMenuItem>
            {
              user?.isSuperAdmin && (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onSelect={() => router.get('/admin')}>
                    <Lock className="mr-2 h-4 w-4" />
                    Admin
                  </DropdownMenuItem>
                </>
              )
            }
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={signOut}>
              <LogOut className="mr-2 h-4 w-4" />
                Sign out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    )};
  
  return (
    <header className="fixed top-0 left-0 w-full z-50 bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-1">

          {/* Logo */}
          <div className="flex-shrink-0">
            <Link href="/">
              <img className="h-8 w-auto"src={b4cast_logo} alt="b4cast" />
            </Link>
          </div>

          { name ? (
            <>
              {companySelector()}
              {userMenu()}
            </>
          ) : (
            <div className="space-x-2">
              <ButtonLink href="/users/sign_in" variant="ghost" size="sm">Sign in</ButtonLink>
              <ButtonLink href="/users/sign_up" variant="default" size="sm" className="rounded-full bg-blue-500 hover:bg-blue-300">Sign up</ButtonLink>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
