import React from 'react'
import { useState, useEffect } from 'react'
import { flashColors } from './flash.js'
import ButtonLink from '@/components/button_link.js';
import { X } from 'lucide-react'


interface FlashMessageProps {
  type: 'notice' | 'alert' | 'error' | 'warning'
  children: string
}

export default function FlashMessage({type, children}: FlashMessageProps) {
  const [isVisible, setIsVisible] = useState(true)

  const isNotice = type === 'notice'

  useEffect(() => {
    if (isNotice) {
      const timer = setTimeout(() => {
        setIsVisible(false)
        setTimeout(()=>{}, 300) // Allow time for fade-out animation
      }, 10000)

      return () => clearTimeout(timer)
    }
  }, [type])

  const handleClose = () => {
    setIsVisible(false)
    setTimeout(()=>{}, 300) // Allow time for fade-out animation
  }

  const baseClasses = "p-4 mt-4 border-l-4 rounded-lg shadow-lg max-w-sm w-full transition-opacity duration-300 ease-in-out"

  return (
    isVisible && <div 
      role="alert" 
      className={`${baseClasses} ${flashColors[type]}`}
      aria-live={isNotice ? 'polite' : 'assertive' }
    >
        <div className="flex justify-between items-center">
          <p className="font-bold">{type}</p>
          
          <ButtonLink
            href="#"
            variant="ghost"
            className="right-2 top-2"
            onClick={handleClose}
          >
            <X className="h-5 w-5" />
          </ButtonLink>
        </div>
        <p className="mt-2">{children}</p>
    </div>
  )
}
