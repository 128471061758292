import React from 'react';
import { Button, ButtonProps, buttonVariants } from "@/components/ui/button.js"
import { Link, InertiaLinkProps } from "@inertiajs/react"
import { cn } from "@/lib/utils.js"

export type ButtonLinkProps = ButtonProps & InertiaLinkProps;

export default function ButtonLink({ className, variant, size, children, ...linkProps }: ButtonLinkProps) {
  const classes = cn(buttonVariants({ variant, size, className }),'no-underline')
  return(
    <Button className={classes} variant={variant} size={size} asChild>
      <Link {...linkProps}>
        {children}
      </Link>
    </Button>
  )
}