import React from 'react'
import { useState } from 'react'
import { useForm } from '@inertiajs/react'
import { Button } from "@/components/ui/button.js"
import { Input } from "@/components/ui/input.js"
import { Label } from "@/components/ui/label.js"
import Card from "@/components/card.js"
//import { toast } from "@/components/ui/use-toast.js"

import { UserType } from '@/types/user.js'

export default function ProfilePage({ user }: { user: UserType }) {
  const form = useForm({
    first_name: user.first_name,
    last_name: user.last_name,
  })

  const { data, setData, errors, processing } = form
  const [isEditingFirstName, setIsEditingFirstName] = useState(false)
  const [isEditingLastName, setIsEditingLastName] = useState(false)
  const [isChanged, setIsChanged] = useState(false)

  const handleChange = (e) => {
    setIsChanged(true)
    setData(e.target.id, e.target.value)
  }

  const handleSave = () => {
    form.put('/users/profile')
    setIsChanged(false)
  }

  return (
    <Card 
      title="Profile" 
      footer={isChanged ? <Button 
        onClick={handleSave}
        disabled={processing}
      >Save</Button> : null
      }
    >
      <div className="space-y-2">
        <Label htmlFor="first_name">First Name</Label>
        {isEditingFirstName ? (
            <Input
              id="first_name"
              value={data.first_name}
              onChange={handleChange}
              onBlur={() => setIsEditingFirstName(false)}
              autoFocus
            />
          ) : (
            <div
              className="p-2 rounded-md hover:bg-gray-100 cursor-pointer"
              onClick={() => setIsEditingFirstName(true)}
            >
              {data.first_name}
            </div>
          )}
        </div>
        <div className="space-y-2">
          <Label htmlFor="last_name">Last Name</Label>
          {isEditingLastName ? (
            <Input
              id="last_name"
              value={data.last_name}
              onChange={handleChange}
              onBlur={() => setIsEditingLastName(false)}
              autoFocus
            />
          ) : (
            <div
              className="p-2 rounded-md hover:bg-gray-100 cursor-pointer"
              onClick={() => setIsEditingLastName(true)}
            >
              {data.last_name}
            </div>
          )}
        </div>
        <div className="space-y-2">
          <Label htmlFor="email">Email</Label>
          <div className="p-2 bg-gray-50 rounded-md">{user.email}</div>
      </div>

    </Card>
  )
}