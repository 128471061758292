import React, { useRef} from 'react'
import { useForm } from '@inertiajs/react'
import { Button } from "@/components/ui/button.js"
import { Input } from "@/components/ui/input.js"
import { Label } from "@/components/ui/label.js"
import { Checkbox } from "@/components/ui/checkbox.js"
import AuthForm from './AuthForm.js'
import ButtonLink from '@/components/button_link.js'

export default function SignIn() {
  const form = useForm({
    email: '',
    password: '',
    remember_me: false,
  })
  const formRef = useRef<HTMLFormElement>(null)

  const { data, setData, errors, processing } = form

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.transform(data => ({user: data}));
    form.post('/users/sign_in');
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.id, e.target.value)
  }
 
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit(e)
    }
  }

  return (
    <AuthForm 
      title="Sign in" 
      description="Enter your credentials to access your account"
      onSubmit={onSubmit}
      formRef={formRef}
      footer={
        <>
          <ButtonLink variant="link" href="/forgot-password" className="text-sm">
            Forgot your password?
          </ButtonLink>
          <ButtonLink variant="link" href="/users/sign_up" className="text-sm">
            Don't have an account? Sign up
          </ButtonLink>
        </>
      }
    >
      <Input
        id="email"
        type="email"
        placeholder="email"
        value={data.email}
        onChange={onChange}
        onKeyDown={onKeyDown}
        required
      />
      {errors.email && (
        <div className="text-red-500 px-3 py-2 font-medium">
          {errors.email.join(', ')}
       </div>
      )}

      <Input
        id="password"
        type="password"
        placeholder="password"
        value={data.password}
        onChange={onChange}
        onKeyDown={onKeyDown}
        required
      />
      <div className="flex items-center space-x-2">
        <Checkbox
          id="remember-me"
          checked={data.remember_me}
          onCheckedChange={(checked) => setData("remember_me", checked)}
        />
      <Label htmlFor="remember-me">Remember me</Label>
      </div>

      <Button 
        type="submit"
        disabled={processing}
        className="w-full"
      >Sign in</Button>

    </AuthForm>
  )
}