import React, { ReactNode } from 'react';
import Card from './card.js';

export interface FormProps {
  children: ReactNode;
  title: string;
  description: string;
  footer?: ReactNode;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export default function Form({ children, title, description, footer, onSubmit }: FormProps) {
  return (
    <Card title={title} description={description} footer={footer}>
      <form onSubmit={onSubmit} className="space-y-4">
        {children}
      </form>
    </Card>
  )
}