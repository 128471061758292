import React from 'react';
import CompanyForm, { Country } from './CompanyForm.js'
import { CompanyType } from '@/types/company.js'
import { InertiaFormProps } from '@inertiajs/react/types/useForm.js';

interface NewProps{
  company: CompanyType,
  countries: Country[]
}
export default function New({ company, countries }: NewProps) {

  const onSubmit = (form: InertiaFormProps<any>) => {
    form.transform((data) => ({ company: data }))
    form.post('/companies')
  };

  return (
    <CompanyForm
      company={company}
      onSubmit={onSubmit}
      submitText="Create Company"
      countries={countries}
    />
  )
}
