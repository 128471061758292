
import Chat from './chat'
import React from 'react'
import ErrorBoundary from '@/components/error_boundeary'

export default function Show({ chat, saved_message }) {

  const key = `chat_${chat.id}`
  return (
    <ErrorBoundary>
      <Chat key={key} chat={chat} saved_message={saved_message}/>
    </ErrorBoundary>
  )
}
