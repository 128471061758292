import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import Color from 'color';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
};

export function generateColorPalette(baseColor: string) {
  const color = Color(baseColor);
  
  return {
    50: color.lighten(0.45).hex(),
    100: color.lighten(0.40).hex(),
    200: color.lighten(0.30).hex(),
    300: color.lighten(0.20).hex(),
    400: color.lighten(0.10).hex(),
    500: color.hex(),
    600: color.darken(0.10).hex(),
    700: color.darken(0.20).hex(),
    800: color.darken(0.30).hex(),
    900: color.darken(0.40).hex(),
  };
}
