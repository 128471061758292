import React from 'react';
import Header from './header.js';
import Flash from './flash.js';
import { usePage } from '#inertiajs/react';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { flash, user } = usePage().props

  return (
    <main className="min-h-screen flex flex-col">
      <Header user={user}/>
      {Object.keys(flash).length > 0 && <Flash flash={flash} />}

      <article className="flex-grow mt-10 sm:mt-16">
        <div className="mx-auto px-2 py-2">
          {children}
        </div>
      </article>

      <footer className="bg-muted py-1 text-center text-slate-500 text-sm">
          &copy; {new Date().getFullYear()} b4cast
      </footer>
    </main>
  )
}